import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Box,
} from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getDebtToken } from "../../services/auth";
import axios from 'axios';
import * as S from "./styles";

const Plugins = ({ setParentValue }) => {
  const [loading, setLoading] = useState(false);
  const [plugins, setPlugins] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState(null);
  const [formData, setFormData] = useState({
    label: '',
    link: '',
  });


  useEffect(() => {
    fetchPlugins();
  }, []);

  const fetchPlugins = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}plugins`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      setPlugins(data);
    } catch (error) {
      console.error('Error fetching plugins:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForm = (pluginId = null) => {
    if (pluginId) {
      const plugin = plugins.find(p => p.id === pluginId);
      setSelectedPlugin(plugin);
      setFormData(plugin);
    } else {
      setSelectedPlugin(null);
    }
    setOpen(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
    setSelectedPlugin(null);
    setFormData({
      label: '',
      link: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    try {
      if (selectedPlugin) {
        await axios.put(`${process.env.REACT_APP_DEBT_API}admin/editPlugin/${selectedPlugin.id}`, formData, {
          headers: {
            'Authorization': `Bearer ${getDebtToken()}`,
            'Content-Type': 'application/json'
          }
        });
      } else {
        await axios.post(`${process.env.REACT_APP_DEBT_API}admin/createPlugin`, formData, {
          headers: {
            'Authorization': `Bearer ${getDebtToken()}`,
            'Content-Type': 'application/json'
          }
        });
      }
      handleCloseForm();
      fetchPlugins();
    } catch (error) {
      console.error('Error saving plugin:', error);
    }
  };

  const handleDelete = async (pluginId) => {
    if (window.confirm('Tem certeza que deseja excluir este plugin?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_DEBT_API}plugins/${pluginId}`, {
          headers: {
            'Authorization': `Bearer ${getDebtToken()}`,
            'Content-Type': 'application/json'
          }
        });
        fetchPlugins();
      } catch (error) {
        console.error('Error deleting plugin:', error);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb><button onClick={() => setParentValue("plugins")}>Home</button> <span>&gt;</span> <span>Plugins</span> <span>&gt;</span></S.Breadcrumb>
          <S.Title>Listagem</S.Title>
        </S.Content>
        <S.Actions>
          <S.AddUser onClick={() => { handleOpenForm() }}><span>+</span>Novo Plugin</S.AddUser>
        </S.Actions>
      </S.Header>

      <S.Card>
        <S.TableHead>
          <span className="row-4">Nome</span>
          <span className="row-3">Link</span>
          <span className="row-2">Modificado em</span>
          <span className="row-2"></span>
        </S.TableHead>
        <S.DataTable>
          {plugins.length > 0 && plugins.map((plugin, index) => {
            return <S.Line key={index} id={plugin.id}>
              <span className="row-4">{plugin.label}</span>
              <span className="row-3">{plugin.link}</span>
              <span className="row-2">{format(new Date(plugin.updatedAt), 'dd/MM/yyyy', { locale: ptBR })}</span>
              <span className="row-2">
                <S.LineActions>
                  <S.EditBtn onClick={() => handleOpenForm(plugin.id)}><img src="./edit.svg" alt="Editar"></img></S.EditBtn>
                  <S.DeleteBtn onClick={() => handleDelete(plugin.id)}><img src="./delete.svg" alt="Deletar"></img></S.DeleteBtn>
                </S.LineActions>
              </span>
            </S.Line>
          })}
        </S.DataTable>
        {(plugins.length === 0) && <div>Não existem plugins.</div>}
      </S.Card >

      {open && <S.Modal onClose={handleCloseForm}>
        <S.ModalTitle>
          {selectedPlugin ? 'Editar Plugin' : 'Novo Plugin'}
        </S.ModalTitle>
        <form onSubmit={handleSubmitForm}>
          <S.ModalContent id="content">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <input
                label="Nome"
                name="label"
                value={formData.label}
                onChange={handleChange}
                fullWidth
                required
                placeholder="Nome do Plugin"
              />
              <input
                label="Link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                fullWidth
                required
                placeholder="https://..."
              />
            </Box>
            <div>
              <button onClick={handleCloseForm}>Cancelar</button>
              <button type="submit" color="primary">
                {selectedPlugin ? 'Salvar' : 'Criar'}
              </button>
            </div>
          </S.ModalContent>
        </form>
      </S.Modal >}
    </>
  );
};

export default Plugins;
