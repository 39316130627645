import { useCallback, useEffect, useState } from "react";
import * as S from "./styles.js";
import axios from "axios";
import { validarCNPJ } from "../../utils/validadorCnpj.js";
import { validarCEP } from "../../utils/validadorCep.js";
import { mascararCEP, mascararCNPJ } from "../../utils/mascaradores.js";
import { validarCPF } from "../../utils/validadorCPF.js";
import { getDebtToken } from "../../services/auth.js";

const CityHall = ({ setParentValue }) => {

  const [id, setId] = useState("");
  const [image, setImage] = useState("");
  const [data, setData] = useState({
    legalName: "",
    cnpj: "",
    zipCode: "",
    address: "",
    addressComplement: "",
    addressNumber: "",
    district: "",
    cityName: "",
    uf: "",
    notificationTitle: "",
    notificationDescription: "",
    notificationAlert: "",
    notificationSignature: "",
    projectOwner: {
      cpf: "",
      name: "",
      phoneNumber: "",
      email: "",
      position: "",
      sector: ""
    },
    sendgridSender: "",
    sendgridKey: "",
    sendgridTemplateVersionId: "",
    sendgridLastNotificationPeriod: "",
    sendgridEmailDemonstrationAddress: "",
    sendgridIsHardLimit: "",
    twilioAccountSid: "",
    twilioAccountToken: "",
    twilioPhoneNumber: "",
    twilioSenderSid: "",
    twilioMessageTemplateSid: "",
    twilioMessageServiceSid: "",
    twilioNotificationContactPhone: "",
    twilioNotificationContactEmail: "",
    twilioLastNotificationPeriod: "",
    twilioWhatsAppDemonstrationNumber: ""
  })
  const [tab, selectedTab] = useState(1);

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    if (name === "projectOwner.cpf") {
      setData((prevData) => ({
        ...prevData,
        projectOwner: {
          ...prevData.projectOwner,
          cpf: value.replace(/\D/g, ""),
        },
      }));
    } else if (name === "cnpj") {
      setData((prevData) => ({
        ...prevData,
        cnpj: value.replace(/\D/g, ""),
      }));
    } else {
      setData((prevData) => {
        if (name.includes("projectOwner.")) {
          const field = name.split(".")[1];
          return {
            ...prevData,
            projectOwner: {
              ...prevData.projectOwner,
              [field]: newValue,
            },
          };
        } else {
          return {
            ...prevData,
            [name]: newValue,
          };
        }
      });
    }
  };

  const getCityHall = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}manager/cityHallDetails/${id}`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const dataAPI = response.data;
      if(response.data.logoUrl) {
        setImage(response.data.logoUrl);
      }
      setData(dataAPI);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const validators = {
    cnpj: (value) => validarCNPJ(value),
    zipCode: (value) => validarCEP(value),
    legalName: (value) => value.length >= 1,
    address: (value) => value.length >= 1,
    cityName: (value) => value.length >= 1,
    uf: (value) => value.length >= 1,
    cpf: (value) => validarCPF(value),
    name: (value) => value.length >= 1,
    email: (value) => value.length >= 1,
    notificationTitle: (value) => value.length >= 1,
    notificationDescription: (value) => value.length >= 1,
  };

  const checkIsValidInput = (element) => {
    const validator = validators[element.name];
    if (validator) {
      const isValid = validator(element.value);
      element.parentElement.classList.toggle("error", !isValid);
    }
  };

  function checkRequiredFields(data) {
    const requiredFields = [
      "legalName",
      "cnpj",
      "zipCode",
      "address",
      "cityName",
      "uf",
      "projectOwner.cpf",
      "projectOwner.name",
      "projectOwner.email",
      "notificationTitle",
      "notificationDescription"
    ];

    const emptyFields = [];

    requiredFields.forEach((fieldPath) => {
      const fieldValue = fieldPath.split('.').reduce((obj, key) => obj?.[key], data);

      if (!fieldValue?.toString().trim()) {
        emptyFields.push(fieldPath);
      }
    });

    if (emptyFields.length > 0) {
      console.log(emptyFields);
      emptyFields.forEach((fieldName) => {
        let field = document.querySelector(`[name="${fieldName}"]`);
        console.log(field);
        field?.parentElement?.classList.add("error");
      })
      return {
        message: "Campos obrigatórios estão vazios.",
        fields: emptyFields,
        block: true,
      };
    }
  }

  const addUser = useCallback(async (cityHallId) => {

    let addData = {
      id: null,
      cpf: Number(data?.projectOwner?.cpf?.replace(/[^a-zA-Z0-9]/g, '')),
      name: data?.projectOwner.name,
      phoneNumber: data?.projectOwner.phoneNumber,
      email: data?.projectOwner.email,
      position: data?.projectOwner.position,
      sector: data?.projectOwner.sector,
      projectInspector: true,
      userRoleId: "65768be54a468136f718394e",
      cityHallId: cityHallId
    }

    let apiData = {
      email: data.projectOwner.email,
      password: process.env.REACT_APP_DEFAULT_PASSWORD,
      apps: {
        "debt": true,
        "docs": false,
        "patr": false,
        "lgpd": false,
        "paper": false,
        "pfpj": false,
        "simn": false
      }
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_DEBT_API}user/new`, addData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 201) {
        alert("Criado com sucesso!");
      }

      await axios.post(`${process.env.REACT_APP_DATA_API}user`, apiData, {
        headers: {
          Authorization: `${process.env.REACT_APP_DATA_AUTH}`,
        }
      });

      alert("Usuário criado com sucesso!");
      setParentValue("prefeitura");

    } catch (error) {
      if (error.status === 400) {
        alert("Usuário já cadastrado!");
      }
      if (error.status === 500) {
        alert("Erro interno de servidor, contate um administrador.");
      }
    }

  }, [data, setParentValue])

  const addCityHall = useCallback(async () => {

    const addData = {
      legalName: data.legalName,
      cnpj: data?.cnpj?.includes(".") ? data?.cnpj.replace(/\D/g, "") : data.cnpj,
      zipCode: data.zipCode,
      address: data.address,
      addressComplement: data.addressComplement,
      addressNumber: data.addressNumber,
      district: data.district,
      cityName: data.cityName,
      uf: data.uf,
      logoUrl: image,
      notificationTitle: data?.notificationTitle,
      notificationDescription: data?.notificationDescription,
      notificationAlert: data?.notificationAlert,
      notificationSignature: data?.notificationSignature,
      projectOwner: {
        cpf: data?.projectOwner.cpf?.includes("-") ? data?.cpf.projectOwner.replace(/\D/g, "") : data?.projectOwner.cpf,
        name: data?.projectOwner.name,
        phoneNumber: data.projectOwner.phoneNumber,
        email: data.projectOwner.email,
        position: data.projectOwner.position,
        sector: data.projectOwner.sector
      },
      sendgridSender: data.sendgridSender,
      sendgridKey: data.sendgridKey,
      sendgridTemplateVersionId: data.sendgridTemplateVersionId,
      sendgridLastNotificationPeriod: data.sendgridLastNotificationPeriod,
      sendgridEmailDemonstrationAddress: data.sendgridEmailDemonstrationAddress,
      sendgridIsHardLimit: data.sendgridIsHardLimit,
      twilioAccountSid: data.twilioAccountSid,
      twilioAccountToken: data.twilioAccountToken,
      twilioPhoneNumber: data.twilioPhoneNumber,
      twilioSenderSid: data.twilioSenderSid,
      twilioMessageTemplateSid: data.twilioMessageTemplateSid,
      twilioMessageServiceSid: data.twilioMessageServiceSid,
      twilioNotificationContactPhone: data.twilioNotificationContactPhone,
      twilioNotificationContactEmail: data.twilioNotificationContactEmail,
      twilioLastNotificationPeriod: data.twilioLastNotificationPeriod,
      twilioWhatsAppDemonstrationNumber: data.twilioWhatsAppDemonstrationNumber
    }

    let requiredFields = checkRequiredFields(addData);

    if (requiredFields?.block) {
      alert(requiredFields.message);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_DEBT_API}open/cityhall`, addData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });

      console.log(response);

      alert("Criado com sucesso!");
      addUser(response.data.id);
    } catch (error) {
      alert("Erro ao criar a prefeitura, contate um administrador.");
      console.log(error);
    }
  }, [data, addUser, image])

  const editCityHall = useCallback(async () => {

    const editData = {
      legalName: data.legalName,
      cnpj: data?.cnpj?.includes(".") ? data?.cnpj?.replace(/\D/g, "") : data.cnpj,
      zipCode: data.zipCode,
      address: data.address,
      addressComplement: data.addressComplement,
      addressNumber: data.addressNumber,
      district: data.district,
      cityName: data.cityName,
      uf: data.uf,
      id: data.id,
      logoUrl: image,
      notificationTitle: data?.notificationTitle,
      notificationDescription: data?.notificationDescription,
      notificationAlert: data?.notificationAlert,
      notificationSignature: data?.notificationSignature,
      projectOwner: {
        cpf: data?.projectOwner?.cpf.toString().includes("-") ? data?.cpf?.toString().projectOwner.replace(/\D/g, "") : data?.projectOwner?.cpf.toString(),
        name: data?.projectOwner.name,
        phoneNumber: data.projectOwner.phoneNumber,
        email: data.projectOwner.email,
        position: data.projectOwner.position,
        sector: data.projectOwner.sector
      },
      sendgridSender: data.sendgridSender,
      sendgridKey: data.sendgridKey,
      sendgridTemplateVersionId: data.sendgridTemplateVersionId,
      sendgridLastNotificationPeriod: data.sendgridLastNotificationPeriod,
      sendgridEmailDemonstrationAddress: data.sendgridEmailDemonstrationAddress,
      sendgridIsHardLimit: data.sendgridIsHardLimit,
      twilioAccountSid: data.twilioAccountSid,
      twilioAccountToken: data.twilioAccountToken,
      twilioPhoneNumber: data.twilioPhoneNumber,
      twilioSenderSid: data.twilioSenderSid,
      twilioMessageTemplateSid: data.twilioMessageTemplateSid,
      twilioMessageServiceSid: data.twilioMessageServiceSid,
      twilioNotificationContactPhone: data.twilioNotificationContactPhone,
      twilioNotificationContactEmail: data.twilioNotificationContactEmail,
      twilioLastNotificationPeriod: data.twilioLastNotificationPeriod,
      twilioWhatsAppDemonstrationNumber: data.twilioWhatsAppDemonstrationNumber
    }

    let requiredFields = checkRequiredFields(editData);

    if (requiredFields?.block) {
      alert(requiredFields.message);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_DEBT_API}open/cityhall`, editData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });

      alert("Editado com sucesso!");
      setParentValue("prefeitura");
    } catch (error) {
      alert("Erro ao editar a prefeitura, contate um administrador.");
      console.log(error);
    }
  }, [data, setParentValue, image])

  const checkFile = useCallback(async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DEBT_API}manager/uploadLogo`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${getDebtToken()}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );

        console.log(response);
        setImage(response.data.url);
      } catch (error) {
        console.error('Erro na requisição:', error.response || error.message);
        setImage("");
      }
    } else {
      console.log('Nenhum arquivo selecionado.');
      setImage("");
    }
  }, []);

  useEffect(() => {
    let cityHallId = localStorage.getItem("cityHallId");
    if (cityHallId) setId(cityHallId);
    localStorage.removeItem("cityHallId");
    if (id !== "") {
      getCityHall();
    }
  }, [getCityHall, id])

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb><button onClick={() => setParentValue("prefeitura")}>Home</button> <span>&gt;</span> <span>Prefeitura</span> <span>&gt;</span></S.Breadcrumb>
          {!id && <S.Title id="head">Criar</S.Title>}
          {id && <S.Title id="head">Editar</S.Title>}
        </S.Content>
      </S.Header>
      <S.Card>

        <S.Head>
          <button className={tab === 1 && "selected"} onClick={() => selectedTab(1)}>Dados da Prefeitura</button>
          <button className={tab === 2 && "selected"} onClick={() => selectedTab(2)}>Dados para cobrança</button>
          <button className={tab === 3 && "selected"} onClick={() => selectedTab(3)}>Dados de configuração</button>
        </S.Head>
        {tab === 1 &&
          <S.Form>
            <div className="row-8">
              <label>
                Nome Legal *
                <input
                  type="text"
                  name="legalName"
                  maxLength={100}
                  required
                  value={data.legalName}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Nome legal é obrigatório</small>
              </label>
            </div>
            <div className="row-4">
              <label className="fullwidth">
                CNPJ *
                <input
                  type="text"
                  name="cnpj"
                  maxLength={18}
                  required
                  value={data.cnpj}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target); mascararCNPJ(e.target) }}
                ></input>
                <small>CNPJ Inválido</small>
              </label>
            </div>
            <div className="row-2">
              <label>
                CEP *
                <input
                  type="text"
                  name="zipCode"
                  required
                  value={data.zipCode}
                  maxLength={9}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target); mascararCEP(e.target) }}
                ></input>
                <small>CEP Inválido</small>
              </label>
            </div>
            <div className="row-4">
              <label>
                Endereço *
                <input
                  type="text"
                  name="address"
                  maxLength={100}
                  required
                  value={data.address}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Endereço é obrigatório</small>
              </label>
            </div>
            <div className="row-1">
              <label>
                Número
                <input
                  type="text"
                  name="addressNumber"
                  maxLength={4}
                  value={data.addressNumber}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                Complemento
                <input
                  type="text"
                  name="addressComplement"
                  maxLength={100}
                  value={data.addressComplement}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label className="fullwidth">
                Bairro
                <input
                  type="text"
                  name="district"
                  maxLength={30}
                  value={data.district}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                Cidade *
                <input
                  type="text"
                  name="cityName"
                  maxLength={30}
                  value={data.cityName}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Cidade é obrigatório</small>
              </label>
            </div>
            <div className="row-1">
              <label>
                UF *
                <input
                  type="text"
                  name="uf"
                  maxLength={2}
                  value={data.uf}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>UF é obrigatório</small>
              </label>
            </div>

            <br />
            <h3>Responsável pelo projeto</h3>
            <div className="row-4">
              <label>
                CPF *
                <input
                  type="text"
                  name="projectOwner.cpf"
                  value={data.projectOwner.cpf}
                  maxLength={14}
                  onChange={handleChange}
                  required
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>CPF Inválido</small>
              </label>
            </div>
            <div className="row-8">
              <label className="fullwidth">
                Nome Completo *
                <input
                  type="text"
                  name="projectOwner.name"
                  value={data.projectOwner.name}
                  required
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Nome Completo é obrigatório</small>
              </label>
            </div>
            <div className="row-4">
              <label>
                Telefone
                <input
                  type="text"
                  name="projectOwner.phoneNumber"
                  maxLength={11}
                  value={data.projectOwner.phoneNumber}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
              </label>
            </div>
            <div className="row-8">
              <label className="fullwidth">
                E-mail *
                <input
                  required
                  type="email"
                  name="projectOwner.email"
                  value={data.projectOwner.email}
                  onChange={handleChange}
                ></input>
                <small>E-mail é obrigatório</small>
              </label>
            </div>
            <div className="row-6">
              <label>
                Cargo/Função
                <input
                  type="text"
                  name="projectOwner.position"
                  value={data.projectOwner.position}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-6">
              <label className="fullwidth">
                Setor
                <input
                  type="text"
                  name="projectOwner.sector"
                  value={data.projectOwner.sector}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
          </S.Form>
        }
        {tab === 2 &&
          <S.Form>
            <S.Warning>
              <p>Configure os campos abaixo para personalizar os layouts das cartas e dos e-mails de cobrança que serão enviadas. Utilize as tags para personalizar o conteúdo das mensagens em qualquer um dos campos:</p>
              <br /><p><b>{"{{nome-devedor}}"}</b> - tag para indicar o nome do devedor</p>
              <p><b>{"{{cpf-devedor}}"}</b> - tag para indicar o número do CPF do devedor</p>
              <p><b>{"{{tipo-documento}}"}</b> - tag para indicar o tipo de documento {"(CPF/CNPJ)"}</p>
            </S.Warning>
            <S.ImageSpot>
              <h3>Logo</h3>
              <S.ImageTreshold style={{ background: `url(${image})` }} />
              <S.ImageContainer>
                <S.ImageLabel for="logo">
                  Enviar arquivo
                  <S.ImageInput onInput={(e) => checkFile(e)} type="file" id="logo" name="logo" accept=".jpg, .jpeg, .png" />
                </S.ImageLabel>
              </S.ImageContainer>
            </S.ImageSpot>
            <div className="row-6">
              <label>
                <h3>
                  Título *
                </h3>
                <input
                  type="text"
                  required
                  placeholder="Digite um título"
                  name="notificationTitle"
                  value={data.notificationTitle}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Título é obrigatório</small>
              </label>
            </div>
            <div className="row-6">
              <label>
                <h3>
                  Mensagem principal *
                </h3>
                <input
                  type="text"
                  placeholder="Digite uma mensagem principal"
                  name="notificationDescription"
                  value={data.notificationDescription}
                  onChange={handleChange}
                  onBlur={(e) => { checkIsValidInput(e.target) }}
                ></input>
                <small>Mensagem principal é obrigatório</small>
              </label>
            </div>
            <div className="row-6">
              <label>
                <h3>
                  Aviso de notificação
                </h3>
                <input
                  type="text"
                  placeholder="Digite um aviso de notificação"
                  name="notificationAlert"
                  value={data.notificationAlert}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-6">
              <label>
                <h3>
                  Assinatura
                </h3>
                <input
                  type="text"
                  placeholder="Digite uma Assinatura"
                  name="notificationSignature"
                  value={data.notificationSignature}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
          </S.Form>
        }
        {tab === 3 &&
          <S.Form>
            <S.Warning>
              <p>Configure os campos abaixo para personalizar os valores de API TWILLIO e SendGrid</p>
            </S.Warning>
            <div className="row-1">
              <label>
                sendgridSender
                <input
                  type="text"
                  name="sendgridSender"
                  value={data.sendgridSender}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-1">
              <label>
                sendgridKey
                <input
                  type="text"
                  name="sendgridKey"
                  value={data.sendgridKey}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                sendgridTemplateVersionId
                <input
                  type="text"
                  name="sendgridTemplateVersionId"
                  value={data.sendgridTemplateVersionId}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                sendgridLastNotificationPeriod
                <input
                  type="text"
                  name="sendgridLastNotificationPeriod"
                  value={data.sendgridLastNotificationPeriod}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                sendgridEmailDemonstrationAddress
                <input
                  type="text"
                  name="sendgridEmailDemonstrationAddress"
                  value={data.sendgridEmailDemonstrationAddress}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                sendgridIsHardLimit
                <input
                  type="text"
                  name="sendgridIsHardLimit"
                  value={data.sendgridIsHardLimit}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioAccountSid
                <input
                  type="text"
                  name="twilioAccountSid"
                  value={data.twilioAccountSid}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioAccountToken
                <input
                  type="text"
                  name="twilioAccountToken"
                  value={data.twilioAccountToken}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                twilioPhoneNumber
                <input
                  type="text"
                  name="twilioPhoneNumber"
                  value={data.twilioPhoneNumber}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioSenderSid
                <input
                  type="text"
                  name="twilioSenderSid"
                  value={data.twilioSenderSid}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                twilioMessageTemplateSid
                <input
                  type="text"
                  name="twilioMessageTemplateSid"
                  value={data.twilioMessageTemplateSid}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                twilioMessageServiceSid
                <input
                  type="text"
                  name="twilioMessageServiceSid"
                  value={data.twilioMessageServiceSid}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-3">
              <label>
                twilioNotificationContactPhone
                <input
                  type="text"
                  name="twilioNotificationContactPhone"
                  value={data.twilioNotificationContactPhone}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioNotificationContactEmail
                <input
                  type="text"
                  name="twilioNotificationContactEmail"
                  value={data.twilioNotificationContactEmail}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioLastNotificationPeriod
                <input
                  type="text"
                  name="twilioLastNotificationPeriod"
                  value={data.twilioLastNotificationPeriod}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
            <div className="row-2">
              <label>
                twilioWhatsAppDemonstrationNumber
                <input
                  type="text"
                  name="twilioWhatsAppDemonstrationNumber"
                  value={data.twilioWhatsAppDemonstrationNumber}
                  onChange={handleChange}
                ></input>
              </label>
            </div>
          </S.Form>
        }
        <S.Actions className="row-12">
          {!id && <S.BtnSave onClick={() => { addCityHall() }}>Salvar prefeitura</S.BtnSave>}
          {id && <S.BtnSave onClick={() => { editCityHall() }}>Salvar</S.BtnSave>}
          <S.BtnCancel onClick={() => { setParentValue("prefeitura") }}>Cancelar</S.BtnCancel>
        </S.Actions>
      </S.Card>
    </>
  )
}

export default CityHall;