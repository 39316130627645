import styled from "styled-components";

export const Title = styled.h1`
box-sizing: border-box;
color: rgb(8, 11, 14);
display: block;
font-size: 24px;
font-weight: 400;
letter-spacing: 0.3px;
line-height: 24px;
margin: 8px 0;
text-align: left;
text-size-adjust: 100%;
`;

export const Breadcrumb = styled.h3`
display: flex;
flex-direction: row;
margin: 0;
font-family: Inter, sans-serif;
& > button, & > span {
cursor: pointer;
background: none;
  padding: 0 2px;
  border: 0;
  color: rgb(167, 169, 171);
  display: block;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19.2px;
  text-align: left;
  text-decoration-color: rgb(167, 169, 171);
}
`;

export const FoundUsers = styled.h3`
  display: flex;
  flex-direction: row;
  margin: 0;
  & > span {
  cursor: pointer;
  background: none;
    padding: 0 2px;
    border: 0;
    color: rgb(167, 169, 171);
    display: block;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 19.2px;
    text-align: left;
    text-decoration-color: rgb(167, 169, 171);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  `;

export const Content = styled.div`
  width: 60%;
  `
export const Actions = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
`

export const AddUser = styled.button`
  border-radius: 100px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  background: #143968;
  padding: 6px 36px 6px 50px;
  margin-right: 8px;
  position: relative;
  border: 1px;
  & > span {
    position: absolute;
    top: 45%;
    left: 25px;
    transform: translateY(-50%);
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 300;
  }
`;

export const ExportXLSX = styled.button`
  border-radius: 100px;
  color: #143968;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #fff;
  padding: 6px 20px;
  border: 1px solid #143968;
`;

export const Card = styled.div`
  min-height: 1px;
  padding: 1.25rem;
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.5rem;
  align-items: center;
  flex-direction: column;
`;

export const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;

    &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-16px, -50%);
    width: 25px;
    height: 25px;
    z-index: 1;
    background-image: url("/search_icon.png");
    background-size: cover;
    background-position: center;
  }
`

export const CardTitle = styled.h3`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  text-transform: uppercase;
  color: #143968;
  margin-right: 16px;
`

export const Searchbar = styled.input`
  padding: 12px 40px 8px 12px;
  width: 300px;
  background-color: #fff;
  border-radius: 0.3rem;
  transition: all 0.25s;
  border: 1px solid rgba(0,0,0,0.125);
  position: relative;
  font-family: Inter;
  font-size: 14px;

  &::placeholder {
    font-family: Inter;
    font-size: 14px;
    color: rgba(0,0,0,0.33);
  }

  &:hover {
    border: 1px solid #000;
    outline: none;
  }

  &:focus-visible {
    border: 1px solid #143968;
    outline: none;
  }
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const TableHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.125);

  & > span {
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #143968;
    padding-top: 18px;
    padding-bottom: 18px;
    text-transform: uppercase;
    
    &.row-1 {
      width: 8.3333%;
    }
    &.row-2 {
      width: 16.6666%;
    }
    &.row-3 {
      width: 25%;
    }
    &.row-4 {
      width: 33.3333%;
    }
    &.row-5 {
      width: 41.6666%;
    }
  }
`;

export const DataTable = styled.div`
  width: 100%;
  max-width: 100%;
`;

// export const Line = styled.div`

//   & > span {
//     padding: 20px 0;
//     font-family: Inter;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 18px;
//     letter-spacing: 0px;
//     text-align: left;
//     color: #3F3F3F;
//     display: inline-block;
//     vertical-align: top;
//     position: relative;

//     &.row-1 {
//       width: 8.3333%;
//     }

//     &.row-2 {
//       width: 16.6666%;
//     }

//     &.row-3 {
//       width: 25%;
//     }

//     &.row-4 {
//       width: 33.3333%;
//     }

//     &.row-5 {
//       width: 41.6666%;
//     }
//   }
// `;

export const Line = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.125);

  & > span {
    padding: 12px 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #3F3F3F;
    display: inline-block;
    vertical-align: center;

    &.row-1 {
      width: 8.3333%;
    }

    &.row-2 {
      width: 16.6666%;
    }

    &.row-3 {
      width: 25%;
    }

    &.row-4 {
      width: 33.3333%;
    }

    &.row-5 {
      width: 41.6666%;
    }

    & small {
      font-family: Inter, sans-serif;
      font-size: 11px !important;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #AEB0B2;
    }
  }
`;
export const Circle = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 25px;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${(props) => props.$bgcolor};
`

export const LineActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const EditBtn = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  `;

export const DeleteBtn = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: calc(100vh);
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
`;


export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  overflow: hidden;
`;

export const ModalTitle = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #143968;
  width: 100%;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-family: Inter, sans-serif;
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & form {
    width: 100%;
    max-width: 320px;
  }
  
  & input {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    background: #FFFFFF;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    
    &:focus {
      outline: none;
      border-color: #143968;
      box-shadow: 0 0 0 1px #143968;
    }
    
    &::placeholder {
      color: rgba(0, 0, 0, 0.33);
    }
  }
  
  & > div {
    width: 100%;
    max-width: 320px;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
    
    button {
      min-width: 120px;
      padding: 8px 24px;
      border-radius: 100px;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.25s;
      
      &:first-child {
        background: #FFFFFF;
        color: #143968;
        border: 1px solid #143968;
        
        &:hover {
          background: #F5F5F5;
        }
      }
      
      &:last-child {
        background: #143968;
        color: #FFFFFF;
        border: 1px solid #143968;
        
        &:hover {
          background: #0f2a4a;
        }
      }
    }
  }
`;