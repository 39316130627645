import styled from "styled-components";


export const Line = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.125);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    background-color: #eaeaea;
  }
/* 
  &.disabled {
    background-color: #eeeeee;
  } */
  & > span {
    padding: 12px 4px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #3F3F3F;
    display: inline-block;
    vertical-align: top;

    &.row-1 {
      width: calc(8.3333% - 8px);
    }

    &.row-2 {
      width: calc(16.6666% - 8px);
    }

    &.row-3 {
      width: calc(25% - 8px);
    }

    &.row-4 {
      width: calc(33.3333% - 8px);
    }

    &.row-5 {
      width: calc(41.6666% - 8px);
    }

    & small {
      font-family: Inter, sans-serif;
      font-size: 11px !important;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #AEB0B2;
    }
  }
`;

export const Group = styled.div`
  overflow: hidden;
  max-height: 0px;
  transition: all 0.6s ease;
  padding: 0px 16px;

  ${Line} {
    cursor: default;

    &:hover {
      background-color: rgba(0,0,0, 0.05);
    }
  }

  &.active {
    max-height: 1000px;
  }

  .link{
    padding-left: 10px;
    cursor: pointer;
  }
`;


