import styled from "styled-components";

export const Title = styled.h1`
box-sizing: border-box;
color: rgb(8, 11, 14);
display: block;
font-size: 24px;
font-weight: 400;
letter-spacing: 0.3px;
line-height: 24px;
margin: 8px 0;
text-align: left;
text-size-adjust: 100%;
`;

export const Breadcrumb = styled.h3`
display: flex;
flex-direction: row;
margin: 0;
font-family: Inter, sans-serif;
& > button, & > span {
cursor: pointer;
background: none;
  padding: 0 2px;
  border: 0;
  color: rgb(167, 169, 171);
  display: block;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19.2px;
  text-align: left;
  text-decoration-color: rgb(167, 169, 171);
}
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  `;

export const Content = styled.div`
  width: 60%;
`;


export const Card = styled.div`
  min-height: 1px;
  padding: 1.25rem;
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  & form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-left: 0px;
    width: 100%;
    & > div {
      display: flex;
      flex-wrap: wrap;

      &.row-1 {
        width: 8.3333%;
      }
      &.row-2 {
        width: 16.6666%;
      }
      &.row-3 {
        width: 25%;
      }
      &.row-4 {
        width: 33.3333%;
      }
      &.row-5 {
        width: 16.6666%;
      }
      &.row-6 {
        width: 50%;
      }
      &.row-7 {
        width: 58.33333%;
      }
      &.row-8 {
        width: 64.6666%;
      }

      &.direction-row {
        flex-direction: row;
        align-items: center;
        & > label {
          width: initial;
          & > input {
            width: initial;
          }
        }
      }

      & > label {
        margin: 8px 16px;
        width: calc(100% - 32px);
        font-size: 14px;
        font-weight: 400;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #3a4a5b;
        margin-left: 0px;

        & > input {
          font-family: Inter, sans-serif;
        }

        & > input[type="text"] {
          width: 100%;
          padding: 8px 0;
          border: 0;
          border-bottom: 1px solid black;
          border-radius: 0;
          font-size: 15px;
          &:focus-visible {
            outline: none;
          }
        }

        & > small {
          display: none;
        }
        
        &.error {
          transition: all 0.3s;
          color: red;
          & > input {
            color: red;
            border-bottom: 1px solid red;
          }
          & > small {
            display: initial;
          }
        }
      }
    }
  }
`;

export const CardTitle = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: left;
  color: #00455A;
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const TableHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.125);

  & > span {
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #143968;
    padding-top: 18px;
    padding-bottom: 18px;
    text-transform: uppercase;
    
    &.row-1 {
      width: 8.3333%;
    }
    &.row-2 {
      width: 16.6666%;
    }
    &.row-3 {
      width: 25%;
    }
    &.row-4 {
      width: 33.3333%;
    }
    &.row-5 {
      width: 41.6666%;
    } 
    &.row-6 {
      width: 50%;
    }
    &.row-7 {
      width: 58.3333%;
    }
    &.row-8 {
      width: 66.6666%;
    }
    &.row-9 {
      width: 75%;
    }
    &.row-10 {
      width: 83.3333%;
    }
    &.row-11 {
      width: 92.6666%;
    }
    &.row-12 {
      width: 100%;
    }
  }
`;

export const DataTable = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  & > div + div {
    border-top: 1px solid rgba(0,0,0,0.125);
  }
  & > h2 {
    padding: 32px 0 16px 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
    text-transform: uppercase;
    color: #143968;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-wrap: nowrap;
  & > p, & > div {
    margin: 0;
    padding: 16px 0;
    font-family: Inter;
    color: #00455A;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

    &.row-1 {
      width: 8.3333%;
    }

    &.row-2 {
      width: 16.6666%;
    }

    &.row-3 {
      width: 25%;
    }

    &.row-4 {
      width: 33.3333%;
    }

    &.row-5 {
      width: 41.6666%;
    }
    &.row-9 {
      width: 75%;
    }

    & small {
      font-family: Inter;
      font-size: 11px !important;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #AEB0B2;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px; 
  align-items: center;
  justify-content: center;
  & > h3 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
    text-transform: uppercase;
    color: #143968;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0 0 0;
  width: 100%;
`;

export const BtnSave = styled.button`
  border-radius: 100px;
  border: none;
  color: #FFFFFF;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  background: #143968;
  min-width: 200px;
  padding: 12px 8px;
  max-width: 200px;
  cursor: pointer;
  margin-left: 16px;
  `;


export const BtnCancel = styled.button`
padding: 12px 8px;
border-radius: 100px;
color: #646464;
font-family: Inter, sans-serif;
cursor: pointer;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
margin-left: 16px;
text-transform: uppercase;
border: none;
min-width: 170px;
max-width: 170px;
background-color: #fff;
border: 1px solid #ccc;
transition: all 0.25s;
&:hover {
  transition: all 0.25s;
  background-color: #eee;
}
`;
