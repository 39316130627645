/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { auth } from "./firebaseConfig";

function Logout() {
  let navigate = useNavigate();

  useEffect(() => {
    auth.signOut();

    let logged = true

    if(!Cookies.get('@username')) {
      logged = false
    }

    Cookies.remove("@innovaContol_current_user");
    Cookies.remove("@innovally");
    Cookies.remove("@inovally");
    Cookies.remove("@username");
    Cookies.remove("@debtToken");

    if(logged) {
      document.location = process.env.REACT_APP_DEBT_URL + 'logout';
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <div>
    </div>
  );
}

export default Logout;
