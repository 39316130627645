import React, { useCallback, useEffect, useState } from "react";
import { Container, Content } from "../styles/setting";
import Header from "../components/header/header";
import { isAuthenticated } from "../services/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as S from "../components/listing/styles";
import * as Sop from "../styles/operations";
import axios from "axios";


function Operations() {
  const [isStaff, setIsStaff] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [downloading, setDownloading] = useState(null);
  const navigate = useNavigate();

  const verifyIsStaff = useCallback(async () => {
    if (Cookies.get("@inovally")) {
      const personalValues = Cookies.get("@inovally");
      if (JSON.parse(personalValues).email !== "cidade_modelo@inovally.com.br" && JSON.parse(personalValues).email !== "isabel.anjos@inovally.com.br" && JSON.parse(personalValues).email !== "josue.oliveira@inovally.com.br" && JSON.parse(personalValues).email !== "junior.ribeiro@inovally.com.br") setIsStaff(false);
    }
  }, [setIsStaff]);

  useEffect(() => {
    verifyIsStaff();
  }, [verifyIsStaff, isStaff])

  useEffect(() => {
    if (!isAuthenticated() || !isStaff) {
      navigate('/');
    }
  }, [isStaff, navigate])

  const getData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_DATA_API}bucket/inovapapers-pdfs-assinados`,
        {
          headers: {
            'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const data = response.data;
      setDataTable(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getZip = async (day, group, index) => {
    setDownloading(index)
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_DATA_API}bucket/inovapapers-pdfs-assinados/${day}/${group}`,
        {
          headers: {
            'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
            'Content-Type': 'application/json'
          }
        }
      );

      let link = response.data.download_url
      setTimeout(() => {
        window.open(link, '_blank');
        setDownloading(null)
      }, 5000); 

    } catch (error) {
      console.log(error);
    }

  };

  const openGroup = (index) => {
    return () => {
      let group = document.getElementsByClassName("group")[index];
      if (group.classList.contains("active")) {
        group.classList.remove("active");
      } else {
        group.classList.add("active");
      }
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <Container>
        <Content>
          <S.Header>
            <S.Content>
              <S.Title>Arquivos</S.Title>
            </S.Content>
          </S.Header>
          <S.Card>
            <S.Table>
              <S.TableHead>
                <span className="row-2">Nome</span>
                <span className="row-4"></span>
                <span className="row-3"></span>
                <span className="row-2">Baixar</span>
              </S.TableHead>
              <S.DataTable>
                {dataTable?.length > 0 && dataTable.map((row, index) => {
                  return (
                    <>
                    <Sop.Line key={index + "-line"} onClick={openGroup(index)}>
                      <span className="row-2">
                        <b>{row?.diretory}</b>
                      </span>
                      <span className="row-3"></span>
                      <span className="row-4"></span>
                      <span className="row-1">
                      </span>
                    </Sop.Line>
                    <Sop.Group key={index + "-group"} className="group">
                      {row.files.map((file, index) => {
                        return (
                          <Sop.Line key={index + "-line"}>
                            <span className="row-2">
                              {file.name}
                            </span>
                            <span className="row-4"></span>
                            <span className="row-3"></span>
                            <span className="row-2 link">
                            <a href className="manual-download"
                              onClick={() => getZip(row.diretory, file.name, index)}
                            >
                              {downloading === index ? (
                                <span className="loading">Baixando...</span>
                              ) : (
                                'Baixar'
                              )}
                            </a>
                            </span>
                          </Sop.Line>
                        );
                      })}
                    </Sop.Group>
                    </>
                  );
                })}
              </S.DataTable>
              {(dataTable.length === 0) && <S.Error><h3>Não existem arquivos.</h3></S.Error>}
            </S.Table>
          </S.Card>
        </Content>
      </Container>
    </>
  );
}

export default Operations;
