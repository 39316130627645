import * as S from "./styles";
import gifLoading from "../../assets/loading.gif";

const Loading = (props) => {
  return (
    <>
      {props.isActive ? (
        <S.Loading>
          <img src={gifLoading} alt="loading" />
        </S.Loading>
      ) : null}
    </>
  )
}

export default Loading;