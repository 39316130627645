import React from "react";
import { Header, Container, SideMenu } from "../styles/inovaView";
import { Link } from "react-router-dom";
import IconHome from '../assets/home.png';
import IconSair from '../assets/sair.png';
import IconMaps from '../assets/maps.png';

function InovaView() {
  return (
    <>
        <Header>
          <div className="logo">
            <Link to={`/`}>
              <img src={IconHome} alt="InovaPATR" />
            </Link>
          </div>
          <div className="user">
            <span>Usuário</span>
            <Link to={`/logout`}>
              <img src={IconSair} alt="Sair" />
            </Link>
            </div>
        </Header>
        <Container>
        <SideMenu>
          <div>
            <img src={IconMaps} alt="Maps" />
          </div>
        </SideMenu>
          <iframe title="InovaView_Reports" 
              src="https://app.powerbi.com/view?r=eyJrIjoiNTM0NjdlYmMtMWJkYS00MzkxLWFhYjAtNzk2Y2Y2MGQ0ZDMyIiwidCI6IjZmMDhlMTU0LTE1N2QtNGVjZC05YzU4LTdiMzE4ZmY0MjQxZSJ9">
          </iframe>
        </Container>
    </>
  );
}

export default InovaView;
