import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Logout from "./pages/logout";
import ForgotPassword from "./pages/forgotPassword";
import InovaView from "./pages/inovaView";
import Settings from "./pages/settings";
import Operations from "./pages/operations";

function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/operations",
      element: <Operations />,
    },
    {
      path: "/esqueci-minha-senha",
      element: <ForgotPassword />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/inova-view",
      element: <InovaView />,
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
